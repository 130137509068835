import * as layouts from './layouts';
import * as validators from './validators';

export { default as SubmitButton } from './SubmitButton';
export { default as RadioField } from './RadioField';
export { default as UploadField } from './UploadField';
export { default as SelectField } from './SelectField';
export { default as InputField } from './InputField';
export { default as PasswordField } from './PasswordField';
export { default as DateField } from './DateField';
export { default as YesNoField } from './YesNoField';
export { default as SwitchField } from './SwitchField';
export { default as FormikChangeEffect } from './FormikChangeEffect';
export { default as TextAreaField } from './TextAreaField';
export { default as NumberField } from './NumberField';
export { default as BlueSwitchField } from './BlueSwitchField';
export { default as TagField } from './TagField';
export { UploadFieldLimitedSize } from './UploadField';
export { default as PercentageField } from './PercentageField';
export { default as FloatNumberField } from './FloatNumberField';
export { default as DateFieldFormated } from './DateFieldFormated';
export { validators, layouts };

function snakeToCamel(str) {
    return str.replace(/_([a-z])/g, (match, group) => group.toUpperCase());
}

export const setErrorFromDjango = (errors, { setErrors }) => {
    setErrors(Object.fromEntries(errors.map(error => [snakeToCamel(error.field), error.messages[0]])));
};
