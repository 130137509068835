import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import Item from './Item';

const DateField = ({ name, label, validate, required, fieldOptions, ...props }) => {
    const [field, meta, helpers] = useField({ name, validate });
    const { value } = field;
    const { setValue, setTouched } = helpers;

    const onChange = date => {
        // setValue(dayjs(date).format('YYYY-MM-DD'))
        setValue(date ? date.add(4, 'hour') : date);
    };

    const onBlur = useCallback(() => {
        setTouched(true);
    }, [setTouched]);

    return (
        <Item label={label} meta={meta} name={name} required={required}>
            <DatePicker
                {...props}
                nBlur={onBlur}
                name={name}
                onChange={onChange}
                required={required}
                value={dayjs(value).isValid() ? dayjs(value) : value}
            />
        </Item>
    );
};

DateField.propTypes = {
    fieldOptions: PropTypes.shape({}),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

export default memo(DateField);
